<template>
  <!--头部-->
  <!-- ,background:bgImg?'url('+bgImg+')':'#fff', -->
  <!-- ,'background-image':bgImg?'url('+bgImg+')':'#fff','background-size':'cover', -->
  <div ref="yue_tao_box" class="yue_tao_box" :style="{height:'100vh'}">
    <headSearch ref="headTitle" :search-out="headGo" />
    <selectedRecommend
      v-if="isTabIndex===0"
      :recommend-all="recommendAll"
      :settop="settop"
      @tabIndex="homeIndex"
    >
      <headSearch ref="headSearch" :search-out="headSearch" />
    </selectedRecommend>
  </div>
</template>

<script>
import headSearch from './components/search'
import selectedRecommend from './components/recommend'
import { fetchKingKongList, fetchOptimizationBannerList } from '@/services/optimization'
import { wxConfigInit } from '@/utils/wx-api'
import { changeURLArg } from '@/utils/common/utils'

export default {
  name: 'YtOptimization',
  components: {
    headSearch,
    selectedRecommend
  },
  data() {
    return {
      active: '',
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#FC363B', // 背景颜色
        titleData: '',
        fontWeight: '500',
        urlImg: 'arrow-left',
        textColor: '#fff',
        callbackGo: this.callbackGo
      },
      headSearch: {
        isShowSearch: true, // 输入框 title 控制
        isShowReturn: false, // 返回 上一页 显隐
        searchWidth: '94%',
        bgColor: '#FC363B', // 背景颜色
        placeholder: ' ',
        callbackBtnGo: this.callbackBtnGo
      },
      isTabIndex: 0, // tab导航 index
      navigation: {}, // tab导航 data
      bannerlist: {}, // 首页
      recommendAll: null, // 精选推荐 data list
      searchWordInfo: null, // 搜索相关参数
      settop: 0,
      bgImg: ''
    }
  },

  mounted() {
    this.handleShareInfo()// 金刚位
    this.getKingKongList()// 金刚位
    this.getOptimizationBannerList()// banner
  },
  methods: {
    /**
     * 获取金刚位列表
     * 2021-07-13 10:56:05
     * @author SnowRock
     */
    async getKingKongList() {
      const res = await fetchKingKongList()
      const { code, data } = res
      if (code === 200) {
        this.recommendAll = {
          ...this.recommendAll,
          wapIcon: data
        }
      }
    },
    /**
     * 悦淘优选分享
     * 2021年07月15日16:09:08
     * @author SnowRock
     */
    handleShareInfo() {
      const recode = localStorage.getItem('recode') || ''
      wxConfigInit({
        link: changeURLArg(window.location.href, 'codeNumber', recode),
        title: '品质好货 悦淘优选',
        desc: '我发现在这里买零食水果，啤酒饮料，面膜保健，都有折扣！',
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-07-19/18/yuelvhuiA92jbktGB51626690727.png'
      })
    },
    /**
     * 获取金刚位列表
     * 2021-07-13 10:56:05
     * @author SnowRock
     */
    async getOptimizationBannerList() {
      const res = await fetchOptimizationBannerList()
      const { code, data } = res
      if (code === 200) {
        this.searchWordInfo = data.searchWord
        this.headSearch.placeholder = data.searchWord.searchWord
        this.recommendAll = {
          ...this.recommendAll,
          topBanner: data.topBanner
        }
      }
    },
    // 搜索回调
    callbackBtnGo() {
      this.$router.push({
        path: '/goodsSearch',
        query: {
          ...this.searchWordInfo
        }
      })
    },

    // 搜索回调
    callbackGo: function() {
      this.$router.push('/')
    },

    // // 导航 tab  下标切换 复值 isTabIndex
    indexData: function(t) {
      this.isTabIndex = t
    },
    homeIndex: function(r) {
      this.isTabIndex = r
    }
  }
}
</script>

<style lang="less" scoped>

.yue_tao_box {
  text-align: left;
  background: #f6f6f6 left top;
}

</style>
