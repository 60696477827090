<template>
  <div>
    <skeleton v-if="!recommendAll" type="index" />
    <mescroll v-else :up="meScrollUp" :down="mescrollDown">
      <div slot="scrollList" class="recommend_box">
        <slot />
        <div v-if="recommendAll.topBanner && recommendAll.topBanner.length > 0" class="recommend_box_swipe">
          <van-swipe
            :stop-propagation="false"
            :autoplay="4200"
            indicator-color="white"
            @change="onChangeIndex"
          >
            <van-swipe-item
              v-for="(item, index) in recommendAll.topBanner"
              :key="index"
              @click="bannerJump(item, index)"
            >
              <img :src="item.url" alt="">
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator" />
            </template>
          </van-swipe>
        </div>
        <!--2 tab 轮播 金刚位-->
        <div class="recommend_two">
          <div class="recommend_box_list">
            <div class="recommend_box">
              <van-swipe
                ref="swiper"
                class="my-swipe"
                :stop-propagation="false"
                indicator-color="#E33F44"
                :autoplay="5800"
                @change="tabIndex"
              >
                <van-swipe-item>
                  <ul class="shopul">
                    <li
                      v-for="item in recommendAll.wapIcon"
                      :key="item.id"
                      class=""
                      @click="jump(item)"
                    >
                      <div class="shipicondiv">
                        <img :src="item.icon" class="shopicon" alt="">
                      </div>
                      <div class="shopname">{{ item.name }}</div>
                    </li>
                  </ul>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
        </div>
        <!--5 秒杀-->
        <div class="recommend_kill_box" @click="secondKill()">
          <div class="recommend_seckill_ov">
            <div class="recommend_seckill_l">
              <img src="@/assets/images/seckill.png" alt="">
              <div>
                <span class="time">{{ times.h }}</span>: <span class="time"> {{ times.m }}</span>:
                <span class="time"> {{ times.s }}</span>
              </div>
            </div>
            <!--<div class="recommend_seckill_r">
              <div
                v-for="(item, index) in indexFlashGoodsShow"
                :key="index"
                class="recommend_data_time"
                @click="btnSecKillIndex(index)"
              >
                <div
                  class="recommend_data_nub"
                  :class="secKillIndex == index ? 'bf' : ''"
                >
                  {{ item.time }}
                </div>
                <div class="recommend_data_title">
                  <span :class="secKillIndex == index ? 'bfs' : ''">{{
                    item.stateRemind
                  }}</span>
                </div>
              </div>
            </div>-->
          </div>
          <div class="recommend_seckill_swiper">
            <van-swipe
              ref="seckill"
              :autoplay="5100"
              indicator-color="#fff"
              :stop-propagation="false"
              @change="seckill"
            >
              <van-swipe-item
                v-for="(item, index) in indexFlashGoodsShow"
                :key="index"
              >
                <div class="swipe-container">
                  <div
                    v-for="(son, sIndex) in item.goodsList"
                    :key="sIndex"
                    class="recommend_seckill_ds"
                  >
                    <div v-if="son.markerUrl" class="markerUrlIconMall">
                      <img :src="son.markerUrl">
                    </div>
                    <div class="recommend_seckill_img">
                      <img :src="son.goodsCover" alt="">
                    </div>
                    <div class="recommend_seckill_span">
                      {{ son.goodsName }}
                    </div>
                    <div class="recommend_seckill_price">
                      <h3>¥</h3>
                      <h4>{{ son.goodsSkPrice }}</h4>
                      <span>¥{{ son.goodsPrice }}</span>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="more-kill">查看更多秒杀商品 <van-icon name="arrow" /></div>
        </div>
        <!--tab 吸顶 -->
        <van-sticky :offset-top="42" @scroll="scrollChange">
          <div
            class="recommend_tab_title"
            :style="{ background: isFixed ? '#fff' : '' }"
          >
            <div class="recommend_tab_tx">
              <div
                v-for="(item, index) in bottomActivityType"
                :key="index"
                class="recommend_tab_tx_list"
              >
                <div
                  class="recommend_tab_tx_img"
                  :class="tabIndexs == index ? 'bf' : ''"
                  @click="btnTabIndex(item, index)"
                >
                  <h2 :class="tabIndexs == index ? 'bf' : ''">
                    {{ item.name }}
                  </h2>
                  <h3 v-show="!isFixed" :class="tabIndexs == index ? 'bf' : ''">
                    {{ item.sub_name }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </van-sticky>
        <!--瀑布流 list-->
        <div class="recommend_flow_list">
          <skeleton v-if="dataList.length === 0" type="list" />
          <Waterfalls
            v-else
            id="dataList"
            :col="2"
            :list="dataList"
            :index="tabIndexs"
          >
            <template slot-scope="{ data }">
              <!-- {{data.goodName}} -->
              <div class="recommended_item" @click="listLink(data)">
                <div v-if="data.markerUrl" class="markerUrlIcon">
                  <img :src="data.markerUrl">
                  <!-- {{data.markerUrl}} -->
                </div>

                <div class="recommended_item_img">
                  <imgLoading :src="data.goodCover" />
                </div>

                <div class="recommended_item_box">
                  <div class="tit">
                    {{ data.goodName }}
                  </div>
                  <div class="desc">
                    <span v-if="data.couponAmountName && data.couponAmountName !== ''" class="desc_jd">
                      {{ data.couponAmountName !== '' ? data.couponAmountName + '元券' : '' }}
                    </span>
                    <span v-if="data.growthValue" class="desc_one">
                      分享赚¥{{ data.growthValue.share }}
                    </span>
                  </div>
                  <div class="money">
                    <em>¥</em>{{ data.goodVipPrice }}
                    <span>
                      <em>¥</em>
                      <span>{{ data.goodPrice }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </Waterfalls>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import skeleton from '@/component/skeleton/index'

import Vue from 'vue'
import {
  Icon,
  Sticky,
  Swipe,
  SwipeItem
} from 'vant'
import mescroll from '@/component/fresh_scroll/mescroll'
import Waterfalls from '@/component/Waterfalls/index'
import imgLoading from '@/component/imgLoading'
import { countTime } from '@/utils/public'
import {
  fetchOptimizationCategoryList,
  fetchOptimizationCategoryMallList,
  fetchOptimizationKillHomeMallList
} from '@/services/optimization'

Vue.use(Icon)
  .use(Swipe)
  .use(SwipeItem)
  .use(Sticky)

export default {
  name: 'Recommend',
  components: {
    Waterfalls,
    imgLoading,
    mescroll,
    skeleton
  },
  props: {
    recommendAll: {
      type: null,
      default: null
    },
    bannerlist: {
      type: null,
      default: null
    },
    settop: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      isFixed: false,
      headSearch: {
        isShowSearch: true, // 输入框 title 控制
        isShowReturn: false, // 返回 上一页 显隐
        searchWidth: '94%',
        bgColor: '#fff', // 背景颜色
        placeholder: '会员省钱购',
        callbackClick: this.callbackClick // 回调
      },
      current: 0,
      isIndex: 0,
      backgroundImg: '',
      dataAll: '',
      listOne: '',
      imgGoLink: '',
      listTwo: '',
      indexFlashGoodsShow: {}, // 秒杀
      secKillIndex: 0, // 秒杀
      secondActivity: '', // 排名榜
      tabIndexs: 0, // tab
      productList: [],
      bottomActivityType: [],
      type: 1,
      activityId: 1,
      times: '',
      show: true,
      timer: null,
      dataTime: '',
      mescrollDown: {
        use: true,
        top: '42px',
        bottom: '0'
      },
      meScrollUp: {
        toTop: true,
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      dataList: [],

      isTabIndex: 0
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.settop !== 0) {
        this.mescrollDown.top = this.settop + 'px'
      }
    })

    this.gitSeckill()
    this.getOptimizationCategoryList()
  },
  methods: {
    scrollChange(e) {
      this.isFixed = e.isFixed
    },
    countTime(time) {
      const _that = this
      this.timer = setInterval(() => {
        _that.times = countTime(time)
      }, 1000)
    },
    // 第一个轮播图
    onChangeIndex(t) {
      this.current = t
    },
    // 第二轮播 获取下标
    tabIndex(index) {
      this.isIndex = index
    },

    // 秒杀
    gitSeckill() {
      fetchOptimizationKillHomeMallList().then((res) => {
        if (Number(res.code) === 200) {
          this.indexFlashGoodsShow = res.data
          const timeStamp = (new Date().getTime() / 1000).toFixed(0) * 1
          const data = res.data
          const newArr = data.filter((e) => {
            return e.timeStamp - timeStamp >= 0
          })
          this.countTime(newArr[0].timeStamp)
          clearTimeout(this.Timer)
        }
      })
    },
    // 获取品类列表
    getOptimizationCategoryList() {
      fetchOptimizationCategoryList().then((res) => {
        if (Number(res.code) === 200) {
          const { data } = res
          this.bottomActivityType = data
        }
      })
    },
    // 获取商品列表
    getOptimizationCategoryMallList(
      pdType,
      pageNum,
      pageSize,
      successCallback,
      errorCallback
    ) {
      const data = {
        id: this.activityId,
        uid: window.localStorage.getItem('uid') || '',
        pageSize: pageSize,
        page: pageNum
      }
      fetchOptimizationCategoryMallList(data).then((res) => {
        if (Number(res.code) === 200) {
          console.log(res.data)
          successCallback(res.data.goods_info)
        }
      })
    },
    // 秒杀 联动
    seckill(index) {
      this.secKillIndex = index
    },
    // 秒杀 tab
    btnSecKillIndex(t) {
      this.$refs.seckill.swipeTo(t)
      clearTimeout(this.Timer)
    },
    // 点击 tab
    btnTabIndex(t, index) {
      this.dataList = []

      if (this.tabIndexs !== index) {
        this.tabIndexs = index
        this.activityId = t.id
        this.type = t.type
        this.dataList = [] // 在这里手动置空列表,可显示加载中的请求进度
        this.mescroll.resetUpScroll() // 刷新列表数据
      }
    },

    mescrollInit(data) {
      this.mescroll = data
    },

    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getOptimizationCategoryMallList(
        this.type,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.dataList = []
          // 把请求到的数据添加到列表
          // this.dataList = this.dataList.concat(arr);
          this.dataList = arr
          // 数据渲染成功后,隐藏下拉刷新的状态

          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    // 处理跳页面 金刚位 //等 url 返回url 链接地址 字段
    jump(info) {
      // 方法
      const { id, redirectType, redirectUrl = '' } = info
      if (Number(redirectType) === 111) {
        window.location.href = redirectUrl
        return
      }
      this.$router.push({
        path: `/yt-optimization-malls?type=${redirectType}&id=${id}&location=king`
      })
    },
    // 处理跳页面 banner
    bannerJump(info) {
      // 方法
      const { id, redirectType, redirectUrl = '' } = info.redirectParams
      if (Number(redirectType) === 111) {
        window.location.href = redirectUrl
        return
      }
      this.$router.push({
        path: `/yt-optimization-malls?type=${redirectType}&id=${id}&location=banner`
      })
    },
    // list 跳转详情
    listLink(t) {
      this.$store.commit('ScrollDateChange', {
        Scroll: 260,
        tabIndex: this.tabIndexs
      })

      const obj = {
        product_type: 1,
        goodId: t.goodId,
        skuId: t.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    secondKill() {
      this.$router.push({
        path: '/secondKill',
        query: {
          type: 'optimization'
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.recommend_box {
  .my-swipe {
    background: #fff;
    border-radius: 8px;
    padding: 16px 0 0;
  }

  .shopul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 8px;
    min-height: 141px;
  }

  .shopul li {
    width: 20%;
    text-align: center;
    margin-bottom: 16px;
  }

  .shipicondiv {
    text-align: center;
  }

  .shopicon {
    width: 37px;
    height: 30px;
  }

  .shopname {
    color: #333333;
    font-weight: bold;
    margin-top: 8px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
  }

  .shopzhekou {
    font-size: 11px;
    color: #999999;
    margin-top: 6px;
  }

  .recommend_box_swipe {
    max-height: 138px;
    overflow: hidden;
    border-radius: 0px 0px 16px 16px;
    /deep/ .van-swipe-item {
      img {
        width: 100%;
        height: 138px
      }
    }
    .custom-indicator {
      position: absolute;
      right: 5px;
      bottom: 12px;
      padding: 1px 6px;
      font-size: 10px;
      color: #fff;
      border-radius: 2px;
      opacity: 0;

      .custom_indicator_style {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .recommend_box_list {
    .recommend_box {
      background: #fff;
      margin: 12px;
      border-radius: 6px;

      .swipe_box {
        padding: 0 0 18px 0;
        box-sizing: border-box;
        margin: 12px;
        overflow: hidden;

        .swipe_box_data {
          width: 68px;
          float: left;
          margin-right: 2px;
          margin-top: 12px;
          overflow: hidden;

          .swipe_box_image {
            width: 45px;
            height: 45px;
            margin: 0 auto;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .swipe_box_name {
            font-size: 10px;
            text-align: center;
          }
        }

        .swipe_box_img:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }

  .recommend_box_tab {
    display: flex;
    text-align: center;
    margin-top: 12px;

    .recommend_box_index {
      flex: 1;

      .recommend_box_span {
        padding: 2px 12px;
        border-radius: 15px;
        position: relative;
      }

      .recommend_box_span.bf {
        background: #e33f44;
        color: #fff;
      }

      .recommend_box_span.bf:before {
        content: "";
        position: absolute;
        border-top: 6px solid #e33f44;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        height: 0;
        width: 0;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  .recommend_box_swipe_three {
    /deep/ .van-swipe__indicators {
      display: none;
    }

    margin: 0 12px;
    height: 90px;
    border-radius: 6px;
    overflow: hidden;
  }

  .recommend_activity_all {
    position: relative;
    margin-top: 8px;

    .recommend_activity_btn {
      width: 100%;
      height: 90px;
      position: absolute;
      top: 0px;
      left: 0%;
    }

    .recommend_activity_son_list {
      position: absolute;
      width: 90%;
      top: 98px;
      left: 50%;
      transform: translate(-50%);
      overflow: hidden;

      .recommend_activity_son {
        flex: 1;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;

        img {
          width: 100%;
        }
      }

      //.recommend_activity_son:nth-child(3n) {
      //  margin-right: 0;
      //}
    }
  }

  .recommend_kill_box {
    margin: 5px 12px 0 12px;
    border-radius: 6px;
    padding: 9px 6px;
    box-sizing: border-box;
    background-color: #F8DCBE;
    position: relative;
    overflow: hidden;
    * {
      z-index: 99999;
      position: relative;
    }
    &:before{
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-07-13/16/yuelvhuivXRHa2bd5k1626164719.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 1;
    }

    .more-kill {
      text-align: center;
      margin-top: 12px;
      .van-icon {
        transform: translateY(2px);
      }
    }

    .recommend_seckill_ov {
      width: 100%;
      overflow: hidden;
      padding-bottom: 4px;
      box-sizing: border-box;
      padding-top: 4px;
      padding-left: 6px;
    }

    .recommend_seckill_l {
      float: left;
      width: 100px;

      img {
        width: 83px;
        margin-bottom: 5px;
      }

      .time {
        padding: 0 4px;
        background: #000;
        color: #fff;
        margin: 4px 2px 0 0;
        font-size: 10px;
        box-sizing: border-box;
        border-radius: 3px;
      }
    }

    .recommend_seckill_r {
      float: left;
      margin-left: 18px;
      width: calc(100% - 118px);
      display: flex;

      .recommend_data_time {
        flex: 1;
        text-align: center;
        color: #666;

        .recommend_data_nub {
          font-size: 17px;
          height: 22px;
          margin-bottom: 9px;
        }

        .recommend_data_nub.bf {
          color: #e33f44;
        }

        .recommend_data_title {
          margin-top: 6px;
          font-size: 10px;
          white-space: nowrap;
          span {
            box-sizing: border-box;
            font-size: 10px;
            border-radius: 11px;
            padding: 2px 5px;
          }
        }

        .bfs {
          background: #f7263c;
          color: #fff;
        }
      }
    }
  }

  .recommend_seckill_swiper {
    /deep/ .van-swipe__indicators {
      display: none;
    }
    /deep/ .van-swipe-item {
      padding: 10px;
    }

    width: 100%;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    margin-top: 12px;

    /* 轮播图 */
    .swipe-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .recommend_seckill_ds {
      width: 31%;
      position: relative;
      margin-right: 3%;

      .recommend_seckill_img {
        width: 100%;
        height: 101px;
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .recommend_seckill_span {
        font-size: 10px;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .recommend_seckill_price {
        margin-top: 8px;
        font-size: 10px;
        text-align: center;
        color: red;

        h3 {
          display: inline-block;
          font-size: 10px;
          vertical-align: bottom;
          margin-right: 1px;
        }

        h4 {
          display: inline-block;
          font-size: 16px;
          vertical-align: middle;
        }

        h5 {
          display: inline-block;
          color: #fff;
          margin-left: 3px;
          background: red;
          border-radius: 0 5px 5px 5px;
          transform: scale(0.8);
          padding: 2px 3px;
          box-sizing: border-box;
        }
        span {
          display: inline-block;
          vertical-align: bottom;
          margin-left: 5px;
          font-size: 12px;
          color: #999999;
          text-decoration: line-through;
        }
      }
    }

    .recommend_seckill_ds:nth-child(3n) {
      margin-right: 0;
    }
  }

  .recommend_second_activity {
    margin: 0 12px;
    border-radius: 0 0 6px 6px;
    background: #fff;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;

    .recommend_second_list {
      color: #999;
      flex: 1;
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      text-align: center;
      font-size: 10px;

      .recommend_second_title {
        width: 64px;
        margin: 10px auto 2px;

        img {
          width: 100%;
        }
      }

      .recommend_second_goods_img {
        width: 60px;
        margin: 10px auto 2px;

        img {
          width: 100%;
        }
      }
    }

    .recommend_second_list:nth-child(1) {
      border-left: none;
    }
  }

  .recommend_tab_title {
    width: 100vw;
    overflow: hidden;
    .recommend_tab_tx {
      display: flex;

      .recommend_tab_tx_list {
        flex: 1;
        padding: 12px 0;
        box-sizing: border-box;
        text-align: center;

        .recommend_tab_tx_img {
          position: relative;
        }

        h2 {
          font-size: 14px;
        }

        h2.bf {
          transform: scale(1.3);
          font-weight: 500;
        }

        h3 {
          font-size: 12px;
          color: #999;
          margin-top: 5px;
        }

        h3.bf {
          color: red;
          font-weight: 700;
        }

        .recommend_tab_tx_img.bf:before {
          content: "";
          width: 20px;
          height: 6px;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          background: url("~@/assets/images/tabIndex.png") no-repeat;
          background-size: 100% 100%;
          bottom: -10px;
        }
      }
    }
  }

  .recommend_flow_list {
    width: 100%;

    .recommended_item {
      position: relative;

      .recommended_item_img {
        img {
          height: 100px;
        }

        // height: 170px;
      }

      .recommended_item_box {
        margin: 0 8px;
      }

      width: 100%;
      background: #fff;
      border-radius: 9px;
      margin-bottom: 9px;

      overflow: hidden;

      .tit {
        color: #191919;
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /*要显示的行数*/
        -webkit-box-orient: vertical;
        margin: 10px 0 5px 0;
        line-height: 20px;
        font-weight: bold;

        img {
          width: 16px;
          height: 14px;
          border-radius: 4px;
        }
      }

      .desc {
        .desc_one {
          color: red;
          font-size: 10px;
          background: rgba(212, 146, 146, 0.4);
          display: initial;
          border: 1px solid red;
          border-radius: 4px;
          padding: 0 4px;
          box-sizing: border-box;
        }

        .desc_jd {
          width: 68px;
          height: 14px;
          background: url("~@/assets/images/yhj_red.png") no-repeat;
          background-size: 100% 100%;
          font-size: 10px;
          color: #fff;
          padding: 1px 3px;
          box-sizing: border-box;
          margin-right: 12px;
        }
      }

      .money {
        font-size: 17px;
        color: #c82519;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 4px;

        span {
          text-decoration: line-through;
          color: #bbbbbb;
          font-size: 12px;
          margin-left: 2px;
          line-height: 23px;
        }

        em {
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }
}

.markerUrlIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;

  img {
    width: auto;
    height: 70px;
  }
}

.markerUrlIconMall {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;

  img {
    width: auto;
    height: 40px;
  }
}
</style>
